<template>
  <v-container>
    <v-card>
      <v-card-title>
        <span>Proveedores y Acreedores</span>
        <v-spacer />
        <v-btn color="success" dark @click="openAdd">
          <v-icon class="mr-2"> mdi-plus </v-icon>Agregar
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="text-center">
          <v-progress-circular
            :size="70"
            :width="7"
            v-if="load"
            indeterminate
            color="blue"
          ></v-progress-circular>
        </div>
        <v-data-table
          v-if="!load"
          dense
          :headers="headers"
          :items="items"
          no-data-text="Sin Contenido"
          no-results-text="Sin resultados encontrados"
          item-key="id"
          class="elevation-10"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              medium
              color="primary"
              class="mr-2"
              @click="openDetail(item)"
            >
              mdi-eye
            </v-icon>
            <v-icon medium color="warning" class="mr-2" @click="openEdit(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium color="red" @click="openDelete(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:[`item.tipo`]="{ item }">
            <v-chip class="ma-2" dark outlined color="success">{{
              item.prov_acre
            }}</v-chip>
          </template>
          <template v-slot:[`item.id_empresa`]="{ item }">
            <v-chip class="ma-2" dark outlined color="info">{{
              item.todaEmpresa
            }}</v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <IngresaProvAcre
      ref="refDialogIngresaProvAcre"
      @successResponse="successResponse"
      @errorResponse="errorResponse"
    />
    <EditarProvAcre
      :DatosProvAcre="prov_acreObj"
      ref="refDialogEditarProvAcre"
      @successResponse="successResponse"
      @errorResponse="errorResponse"
    />
    <DetailProvAcre
      :DatosProvAcre="prov_acreObj"
      ref="refDialogDetailProvAcre"
      @successResponse="successResponse"
      @errorResponse="errorResponse"
    />
    <SnackbarConfirm
      :messageSnackbar="messageSnackbar"
      @successResponse="openConfirm"
      ref="refSnackbarDelete"
    ></SnackbarConfirm>
    <div class="text-center">
      <v-snackbar v-model="snackbar" :timeout="timeout" color="green">
        <h3>{{ texto_snackbar }}</h3>
      </v-snackbar>
    </div>
    <SnackbarError 
      :messageSnackbar="messageSnackbar"
      @openConfirm="errorResponse"
      ref="refSnackbarError"
    />
  </v-container>
</template>

<script>
import SnackbarError from "../../components/Snackbar/SnackbarError.vue";
import SnackbarConfirm from "../../components/Snackbar/SnackbarConfirm";
import IngresaProvAcre from "./dialog/IngresaProvAcre";
import ProveedorAcreedorService from "../../service/api/proveedorAcreedor/index";
import EditarProvAcre from "./dialog/EditarProvAcre";
import DetailProvAcre from "./dialog/DetailProvAcre";

export default {
  name: "Empresa",
  components: {
    IngresaProvAcre,
    EditarProvAcre,
    DetailProvAcre,
    SnackbarConfirm,
    SnackbarError,
  },
  data() {
    return {
      items: [],
      headers: [
        { text: "Rut", value: "rut" },
        { text: "Nombre", value: "nombre" },
        { text: "Acreedor / Proveedor", value: "tipo" },
        { text: "Para toda empresa", value: "id_empresa" },
        { text: "Acción", value: "actions", sortable: false },
      ],
      prov_acreObj: {},
      messageSnackbar: "",
      prov_acre: "",
      todaEmpresa: "",
      snackbar: false,
      timeout: 2000,
      texto_snackbar: "",
      load: false,
    };
  },
  methods: {
    successResponse() {
      this.getValues();
    },
    errorResponse(response) {
      this.$refs.refSnackbarError.openSnackbar = true;
      this.messageSnackbar = `Error!! El Rut ingresado, ya está registrado`;
      console.log(response)
    },
    async getValues() {
      this.load = true;
      let empresa = parseInt(this.$store.state.empresa);
      let response = await ProveedorAcreedorService.fetchAllAll(empresa);
      (this.items = response.map((resp) => {
        if (resp.tipo == "a") {
          resp.prov_acre = "Acreedor";
        }
        if (resp.tipo == "p") {
          resp.prov_acre = "Proveedor";
        }
        if (resp.id_empresa == null) {
          resp.todaEmpresa = "Si";
        } else {
          resp.todaEmpresa = "No";
        }
        return resp;
      })),
      this.load = false;
    },
    openAdd() {
      this.$refs.refDialogIngresaProvAcre.openDialog = true;
    },
    openDetail(item) {
      this.prov_acreObj = item;
      this.$refs.refDialogDetailProvAcre.openDialog = true;
    },
    openEdit(item) {
      this.prov_acreObj = item;
      this.$refs.refDialogEditarProvAcre.openDialog = true;
    },
    openDelete(item) {
      this.$refs.refSnackbarDelete.openSnackbar = true;
      this.messageSnackbar = `¿Desea eliminar a ${item.nombre}?`;
      this.prov_acreObj = item;
    },
    async openConfirm() {
      let response = await ProveedorAcreedorService.destroy(
        this.prov_acreObj.id
      );
      if (response.data === 200) {
        this.$refs.refSnackbarDelete.openSnackbar = false;
        this.successResponse();
      } else {
        this.$refs.refSnackbarError.openSnackbar = true;
        this.messageSnackbar = `${this.prov_acreObj.nombre}, Tiene alguna factura de compra o venta!! `;
        // this.errorResponse(response);
      }
    },
  },
  mounted() {
    this.getValues();
  },
};
</script>
