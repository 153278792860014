<template>
  <div>
    <v-dialog v-model="openDialog" max-width="1000px" persistent>
      <v-card>
        <v-toolbar color="teal lighten-2" dark>
          <v-toolbar-title class="ml-2"
            >Edición de Proveedor / Acreedor</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn class="mb-1" fab x-small color="red" dark @click="closeDialog"
            >X</v-btn
          >
        </v-toolbar>
        <v-container>
          <v-card>
            <v-form ref="refDialogEditarProvAcre" class="pa-2">
              <v-row>
                <v-col md="3">
                  <v-text-field
                    readonly
                    outlined
                    dense
                    v-model="vmRut"
                    label="Rut...(1333666-5)"
                    :rules="[
                      (v) => !!v || 'Requerido.',
                      (v) => validaRut(v) || 'Rut mal ingresado',
                    ]"
                    disabled
                  ></v-text-field>
                  <!-- <v-btn @click="validaRut"></v-btn> -->
                </v-col>
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="vmNombre"
                    label="Nombre..."
                    :rules="[(v) => !!v || 'Requerido.']"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <template>
                    <v-radio-group v-model="vmProvAcre" mandatory>
                      <v-radio label="Proveedor" value="prove"></v-radio>
                      <v-radio label="Acreedor" value="acre"></v-radio>
                    </v-radio-group>
                  </template>
                </v-col>
                <v-col>
                  <template>
                    <v-radio-group v-model="vmTodasEmpre" mandatory>
                      <v-radio label="Para esta empresa" disabled value="esta"></v-radio>
                      <v-radio
                        label="Para todas"
                        value="todas"
                      ></v-radio> </v-radio-group
                  ></template>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <div class="text-center">
                <v-btn
                  class="mt-2"
                  @click="saveEditProvAcre"
                  dark
                  color="success"
                  >Guardar</v-btn
                >
              </div>
            </v-form>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProveedorAcreedorService from "../../../service/api/proveedorAcreedor/index";
export default {
  props: {
    DatosProvAcre: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    validateForm() {
      return this.$refs.refDialogEditarProvAcre.validate();
    },
    resetForm() {
      return this.$refs.refDialogEditarProvAcre.reset();
    },
  },

  data() {
    return {
      items: [],
      openDialog: false,
      vmRut: "",
      vmNombre: "",
      vmProvAcre: null,
      vmTodasEmpre: null,
    };
  },
  methods: {
    validaRut(rutCompleto) {
      if (rutCompleto) {
        rutCompleto = rutCompleto.replace("‐", "-");
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
        var tmp = rutCompleto.split("-");
        var digv = tmp[1];
        var rut = tmp[0];
        if (digv == "K") digv = "k";

        return this.dv(rut) == digv;
      }
    },

    dv(T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },

    getValues() {
      this.vmRut = this.DatosProvAcre.rut;
      this.vmNombre = this.DatosProvAcre.nombre;
      if (this.DatosProvAcre.tipo == "a") {
        this.vmProvAcre = "acre";
      } else {
        this.vmProvAcre = "prove";
      }
      if (this.DatosProvAcre.id_empresa != null) {
        this.vmTodasEmpre = "esta";
      } else {
        this.vmTodasEmpre = "todas";
      }
    },
    async saveEditProvAcre() {
      if (this.validateForm) {
        let provacre = {};
        provacre.rut = this.vmRut;
        provacre.nombre = this.vmNombre;
        if (this.vmProvAcre == "prove") {
          provacre.tipo = "p";
        }
        if (this.vmProvAcre == "acre") {
          provacre.tipo = "a";
        }
        if (this.vmTodasEmpre == "esta") {
          //se elige empresa que esta logeado
          provacre.id_empresa = parseInt(this.$store.state.empresa);
        } else {
          provacre.id_empresa = null;
        }
        let response = await ProveedorAcreedorService.update(
          provacre,
          this.DatosProvAcre.id
        );
        if (response.status === 200) {
          this.closeDialog();
          this.$emit("successResponse");
        } else {
          this.closeDialog();
          this.$emit("errorResponse", response);
        }
      }
    },

    closeDialog() {
      this.openDialog = false;
    },
  },
  watch: {
    DatosProvAcre(val) {
      if (val) {
        this.getValues();
      }
    },
  },
};
</script>
